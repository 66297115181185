import React from "react"
import SEO from "../components/seo"
import "../styles/settings.scss"
import "../styles/style.scss"

const IndexPage = () => (
  <>
    <SEO title="Virtus: AI-powered strength training coach" />
    <div className="site-wrapper">
      {/* Site Header */}
      <header className="site-header">
        <div className="container">
          <div className="row justify-content-center align-items-center  position-relative">
            <div className="col-sm-4 col-6 col-lg-2 col-xl-2 order-lg-1">
              <div className="brand">
                <a href>
                  <img src={require("../images/main-logo.png")} alt />
                </a>
              </div>
            </div>
            <div className="col-sm-7 col-lg-2 col-xl-2 order-lg-3 d-none d-sm-block"></div>
            <div className="col-sm-1 col-6 col-lg-7 offset-lg-1 col-xl-6 offset-xl-2 order-lg-2 position-static"></div>
          </div>
        </div>
      </header>
      {/* Hero-Area starts */}
      <section className="hero-area">
        <div className="hero-shape">
          <img
            src={require("../images/landing-3-shape.svg")}
            alt
            className="w-100"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 order-md-2 order-lg-1">
              <div className="hero-image">
                <img src={require("../images/hero-phn.png")} alt />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 col-md-6 order-md-1 order-lg-2">
              <div className="hero-left-content">
                <h1>Get strong and fit</h1>
                <p>
                  Get jacked and hit new PRs using AI-powered strength training
                  coach
                </p>
                <div className="hero-btns">
                  <a href="#" className="apple-store">
                    <img src={require("../images/app-store.png")} alt />
                  </a>
                  <a
                    href="http://bit.ly/virtus-android"
                    className="google-play"
                  >
                    <img src={require("../images/google-play.png")} alt />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About starts */}
      <section className="about-section-01">
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xl-5 offset-xl-1 order-1 order-md-2">
                <div className="about-image-01">
                  <img src={require("../images/iPhone-1.png")} alt />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xl-5 order-2 order-md-1">
                <div className="about-content">
                  <h2>Say goodbye to generic templates</h2>
                  <p>
                    Virtus uses Artificial Intelligence and your training
                    history to design the most effective workouts for YOU and
                    your goals!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section-02">
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              <div className="col-lg-6 order-lg-1 col-md-6 col-xl-6">
                <div className="about-image-02">
                  <img src={require("../images/iPhone-2.png")} alt />
                </div>
              </div>
              <div className="col-lg-5 order-lg-2 col-md-6 col-xl-5 offset-xl-1">
                <div className="about-content">
                  <h2>Achieve your goals</h2>
                  <p>
                    Focus on increasing muscle size with Hypertrophy programs or
                    go for maximum strength gains. Smart volume, load, intensity
                    and exercise selection personalized for YOU!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section-03">
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xl-5 offset-xl-1 order-1 order-md-2">
                <div className="about-image-03">
                  <img src={require("../images/iPhone-3.png")} alt />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xl-5 order-2 order-md-1">
                <div className="about-content">
                  <h2>Hit PRs</h2>
                  <p>
                    Follow your progress and continuously improve using
                    autoregulation (RPE), periodization and automatic scheduling
                    of deload weeks. Keep intensity and fatigue at the right
                    levels!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonial section */}
      <section className="testimonial-section">
        <div className="tstimonial-shape">
          <img
            src={require("../images/landing-3-shape-2.svg")}
            alt
            className="w-100"
          />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title">
                <h2>Lifters are loving Virtus</h2>
              </div>
            </div>
          </div>
          <div className="masonry-with-columns">
            <div className="testimonial-card">
              <div className="card-content">
                <p>
                  “You made it so simple! My workouts are perfectly aligned with
                  my goal of losing fat and getting stronger. I'm starting to
                  see results after 2 weeks!“
                </p>
              </div>
              <div className="card-id">
                <div className="id-img">
                  <img src={require("../images/t-nav-1.png")} alt />
                </div>
                <div className="id-content">
                  <h6>Evelyn Anderson</h6>
                  <span>Mom &amp; Lifter</span>
                </div>
              </div>
            </div>
            <div className="testimonial-card">
              <div className="card-content">
                <p>
                  “Simply the best. Better than all the rest. I’d recommend
                  Virtus to beginner and advanced lifters!”
                </p>
              </div>
              <div className="card-id">
                <div className="id-img">
                  <img src={require("../images/t-nav-6.png")} alt />
                </div>
                <div className="id-content">
                  <h6>Jim Morton</h6>
                  <span>Recreational Lifter</span>
                </div>
              </div>
            </div>
            <div className="testimonial-card">
              <div className="card-content">
                <p>
                  “Lifting weights has always been a passion of mine! But
                  following programs from the Internet with spreadsheets was a
                  pain. Now I can easily workout using perfectly personalized
                  plans! Virtus helps my clients too!”
                </p>
              </div>
              <div className="card-id">
                <div className="id-img">
                  <img src={require("../images/t-nav-2.png")} alt />
                </div>
                <div className="id-content">
                  <h6>Katherine Stevenson</h6>
                  <span>Powerlifting Coach</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CTA section */}
      <section className="cta-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="cta-content ">
                <span className="title-top-text">Time to improve</span>
                <h2>Join thousands of lifters who get strong &amp; fit</h2>
                <div className="cta-btns">
                  <a href="#" className="apple-store">
                    <img src={require("../images/app-store.png")} alt />
                  </a>
                  <a
                    href="http://bit.ly/virtus-android"
                    className="google-play"
                  >
                    <img src={require("../images/google-play.png")} alt />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Section 1 */}

      <section className="footer-section">
        <div className="container">
          <div class="row justify-content-md-center">
            <p className="footer-authors">
              Made with love by{" "}
              <a href="https://www.instagram.com/ivakoilova/" target="blank">
                Iva Koilova
              </a>{" "}
              and{" "}
              <a href="https://www.curiousily.com/" target="blank">
                Venelin Valkov
              </a>
            </p>
          </div>
          <div className="footer-bar-wrapper">
            <div className="row align-items-center text-center">
              <div className="col-lg-2 col-md-3 ">
                <div className="footer-icon">
                  <img src={require("../images/footer-logo.png")} alt />
                </div>
              </div>
              <div className="col-lg-6 col-md-6  offset-lg-1">
                <ul className="nav footer-nav">
                  <li className="nav-item">
                    <a className="nav-link active" href="privacy">
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="mailto:hi@virtus.fit">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="copy-right">
                  <p>© Venelin Valkov 2019 All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
)

export default IndexPage
